import React from 'react'
import ScrollMagicContextInner from './ScrollMagicContextInner'

const ScrollMagicContextWrapper = ({ ...other }) => {
  if (typeof window !== 'undefined') {
    const scrollMagic = require('scrollmagic')
    return (
      <ScrollMagicContextInner
        scrollMagic={scrollMagic}
        {...other}
      />
    )
  } else {
    return null
  }
}

export default ScrollMagicContextWrapper
