import React from 'react'
import { graphql } from 'gatsby'
import { TimelineMax, TimelineLite } from 'gsap/all'
import CSSPlugin from 'gsap/CSSPlugin'
import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import firstImage from '../../public/images/landing.png'
import secondImage from '../../public/images/end.png'
import loaderLogo from '../../public/images/loader-logo.png'
import ScrollMagicContext from '../components/ScrollMagic/ScrollMagicContext'
import { theme, media } from '../styles'
const { colors } = theme

const C = CSSPlugin // eslint-disable-line no-unused-vars
const { fonts, fontSizes } = theme

const Container = styled.div`
  max-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  flex-wrap: nowrap;
  overflow-x: scroll;
`

const Loader = styled.div`
  opacity: 0;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const LoadingImage = styled.div`
  img {
    height: auto;
    width: 300px;
    ${media.thone`
      width: 80vw;
    `}
  }
`

const Item = styled.div`
  height: 100vh;
  min-width: 500px;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  ${media.tablet`
    min-width: 200px;
  `}
`

const Title = styled.h1.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  color: ${props => props.color};
  font-family: ${fonts.body};
  font-size: ${fontSizes.superbig};
  letter-spacing: 2px;
  font-weight: 100;
  justify-self: center;
  text-align: center;
  padding: 0 30px;
  cursor: pointer;
  ${media.tablet`
    font-size: ${fontSizes.large};
    padding: 20px;
  `}
`

const Subtitle = styled.h1.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  color: ${props => props.color};
  font-family: ${fonts.heading};
  font-size: ${fontSizes.medium};
  font-weight: 100;
  justify-self: center;
  text-align: center;
  padding: 19px 30px;
  cursor: pointer;
  ${media.tablet`
    font-size: ${fontSizes.small};
    padding: 20px;
  `}
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      transformX: 0 // for transforming projects container
    }

    // refs to document objects
    this.scrollRight = null
    this.loader = null
    this.loader1 = null
    this.loader2 = null
    this.container = null

    // bind functions
    this.loaderTimeline = new TimelineLite({ paused: true })
    this.hideScroll = this.hideScroll.bind(this)
  }

  componentDidMount() {
    this.loaderTimeline
      .set(this.container, { opacity: 0 })
      .set(this.loaderContent, { display: 'none', opacity: 0, y: '+=20', scale: 0.9 })
      .to(this.loader, 0, { opacity: 1 }, '+=0.1')
      .to(this.loaderContent, 0.7, { opacity: 1, y: '-=20', display: 'block', scale: 1, ease: 'Power4.easeOut' })
      .to(this.loaderContent, 0.5, { opacity: 0, y: '+=20', display: 'none' }, '+=1')
      .to(this.loader, 0, { display: 'none' })
      .to(this.container, 1, { opacity: 1, ease: 'Power4.easeOut' }, '+=0.3')
      .play()
  }

  hideScroll() {
    this.animation = new TimelineMax({ paused: true })
      .set(this.scrollRight, { css: { animation: 'none' } })
      .to(this.scrollRight, 2, { x: '+=10', opacity: 0, delay: 0.5 })
      .play()
  }

  render() {
    // const { data: { landingImage } } = this.props

    return (
      <ScrollMagicContext
        location={`home`}
      >
        <Loader ref={ref => { this.loader = ref }}>
          <LoadingImage ref={ref => { this.loaderContent = ref }}>
            <img src={loaderLogo} />
          </LoadingImage>
        </Loader>
        <Container ref={ref => { this.container = ref }}>
          <Item style={{
            backgroundImage: `url(${firstImage})`
          }} />
          <Item>
            <AniLink to="/about">
              <Title shade={colors.text}>Art framed by function.</Title>
              <Subtitle shade={colors.text}>enter site</Subtitle>
            </AniLink>
          </Item>
          <Item style={{
            backgroundImage: `url(${secondImage})`
          }} />
        </Container>
      </ScrollMagicContext>
    )
  }
}

export const query = graphql`
  query IndexQuery {
    landingImage: file(relativePath: { eq: "landing.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
