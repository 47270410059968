import { Component } from 'react'
import PropTypes from 'prop-types'

export default class ScrollMagicContextInner extends Component {
  static propTypes = {
    children: PropTypes.any,
    location: PropTypes.any,
    scrollMagic: PropTypes.any,
    vertical: PropTypes.bool
  }

  static get childContextTypes() {
    return { scrollmagic: PropTypes.any }
  }

  constructor(props) {
    super(props)
    const { scrollMagic, vertical } = this.props
    const { Controller } = scrollMagic

    this.state = {
      scrollmagic: new Controller({
        vertical
      })
    }
  }

  getChildContext() {
    return { scrollmagic: this.state.scrollmagic }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.location.pathname === this.props.location.pathname) return
    this.resetController()
  }

  resetController() {
    this.state.scrollmagic.destroy()
    const { scrollMagic } = this.props
    const { Controller } = scrollMagic
    this.setState({ scrollmagic: new Controller() })
  }

  render() {
    return (
      this.props.children
    )
  }
}
